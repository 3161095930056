import { createContext, useContext, ReactNode } from 'react';
import type { Logger } from '../../../shared';

const LoggerContext = createContext<Logger | undefined>(undefined);
export function useLogger(): Logger {
    const ctx = useContext(LoggerContext);
    if (!ctx) {
        throw new Error('useLogger must be used within the LoggerContext');
    }
    return ctx;
}

export function LoggerProvider({
    logger,
    children,
}: {
    logger: Logger;
    children: ReactNode;
}): JSX.Element {
    return (
        <LoggerContext.Provider value={logger}>
            {children}
        </LoggerContext.Provider>
    );
}
